<template>
  <div class="ml-1 mr-1">
    <div class="iq-card d-flex flex-column w-100 mb-3" style="max-height: -webkit-fill-available !important;">
      <div class="iq-card-body">
        <div id="postDetail" class="row">
          <div class="col-12 p-0">
            <div class="row">
            <div class="col-11 d-flex">
              <!-- userimage -->
              <div class="user-img mr-3 pointer" @click="goToUserProfile(vmPostData)">
                <img v-if="vmPostData.user_image || (vmPostData.org && vmPostData.org.org_profile_img)" :src="getProfileImage(vmPostData)" class="avatar-60 logo-icon" />
                <b-avatar v-else
                  :variant="getBgColorForLetter(vmPostData.org?.org_name ? vmPostData.org.org_name : vmPostData.user_name)"
                  :text="getFirstLetterOfAString(vmPostData.org?.org_name ? vmPostData.org.org_name : vmPostData.org?.org_name ? vmPostData.org?.org_name : vmPostData.user_name)" class="avatar-60">
                </b-avatar>
              </div>
              <!-- userName -->
              <div class="d-flex flex-column pointer" @click="goToUserProfile(vmPostData)">
                <h5 class="mb-0">
                  {{ getProfileName(vmPostData) }}
                </h5>
                <p class="mb-0 text-secondary">
                  {{ getFormatttedTimeAgo(vmPostData.created_on) }}
                </p>
              </div>
            </div>

            <div class="col-1 iq-card-header-toolbar align-items-center">
            <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0 mr-0" class="float-right pt-3 pr-3">
              <template v-slot:button-content>
                <b-link href="javascript:void(0)" class="text-secondary postThreeDotIcon">
                  <i class="ml-0 ri-more-2-line"></i>
                </b-link>
              </template>
              <a v-if="(userData.user_id === vmPostData.user_id) || (userData.user_role === 'USERROLE11111')" class="dropdown-item p-3" @click="openEditProfileModal()" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-pen-to-square"></i></div>
                  <div class="data ml-2">
                    <h6>Edit Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" @click="ugcx_add(vmPostData.post_id, 'POST')" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-eye-slash"></i></div>
                  <div class="data ml-2">
                    <h6>Hide Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" @click="showModelReportFn(vmPostData.post_id, 'POST')" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-circle-exclamation"></i></div>
                  <div class="data ml-2">
                    <h6>Report Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" v-if="userData.user_role === 'USERROLE11111' || userData.user_id == vmPostData.user_id" @click="showAlertForDelete(vmPostData.post_id)" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-trash"></i></div>
                  <div class="data ml-2">
                    <h6>Delete Post</h6>
                  </div>
                </div>
              </a>
            </b-dropdown>
            </div>
            </div>
            <hr />

            <!-- description  -->
            <div class="user-post obverflow-hidden">
              <p class="my-2" v-if="vmPostData && vmPostData.post_desc" style="white-space:pre-wrap;">
                <span v-html="vmPostData.post_desc"></span>
              </p>
              <div v-if="vmPostData.post_images && vmPostData.post_images.length > 0" class="mt-2 d-flex justify-content-center align-items-center postSlideParentDiv">
                <button v-if="vmPostData.post_images && vmPostData.post_images.length > 1" class=".slide_control_btn mr-2 leftArrowIcon" @click="showPrevImage" style="all:unset; cursor:pointer;">
                  <i class="fas fa-chevron-circle-left slide_control_icon text-white" size="3x"></i>
                </button>
                <div class="position-relative w-100" >
                  <b-carousel  height="auto" id="carousel-fade" indicators :interval="0" ref="image_carousel">
                    <b-carousel-slide v-for="(postImage, index) in vmPostData.post_images" :key="index"  :img-src="postImage.image">
                    </b-carousel-slide>
                  </b-carousel>
                </div>
                <button  v-if="vmPostData.post_images && vmPostData.post_images.length > 1" class=".slide_control_btn ml-2 rightArrowIcon" @click="showNextImage" style="all:unset; cursor:pointer;">
                  <i class="fas fa-chevron-circle-right slide_control_icon text-white" size="3x"></i>
                </button>
              </div>
              <!-- video -->
              <div v-if="vmPostData && vmPostData.post_video" class="mt-2 ratio ratio-16x9" >
                <iframe :src="vmPostData.post_video" style="width: 100%; height: 480px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>

            <div class="comment-area pt-3">
              <!-- like and comment and share -->
              <div class="grid " style="display: grid   !important">
                <div class="d-flex justify-content-center align-items-center like">
                  <!-- like button -->
                  <button class="btn-opacity rounded-md p-0" @click="postLike(vmPostData.post_id, 'POST', vmPostData.post_likedby_curr_user)">
                    <i  v-if="vmPostData.post_likedby_curr_user" class="fas fa-thumbs-up text-primary" ></i>
                    <i v-else class="far fa-thumbs-up"></i>
                    <span class="ml-2">Like</span>
                    <span class="ml-2">{{vmPostData.post_like_count ? vmPostData.post_like_count : ""}}</span>
                  </button>
                </div>

                <!-- comment button -->
                <div class="d-flex justify-content-center align-items-center comment">
                  <button  class="btn-opacity rounded-md">
                    <i class="far fa-comment-alt"></i>
                    <span class="ml-2">Comment</span>
                    <span class="ml-2">{{vmPostData.post_comment_count ? vmPostData.post_comment_count : "" }}</span>
                  </button>
                </div>

                <!-- share button  -->
                <div v-if="vmPostData && vmPostData.post_id" class="d-flex flex-fill justify-content-center align-items-center share">
                  <SocialMediaShare :propPostId="vmPostData.post_id"></SocialMediaShare>
                </div>
              </div>
              <hr />

              <div  class="d-flex w-full" >
                <div class="user-img">
                  <b-img v-if="userData.user_image && userData.user_image.profile_pic" :src="userData.user_image.profile_pic.image_thumbnail" style="object-fit: cover;" alt="userimg" class="avatar-40"  rounded="circle" fluid />
                  <b-avatar v-else class="logo-icon" :variant="getBgColorForLetter(userData.user_name)" :text="getFirstLetterOfAString(userData.user_name)" >
                  </b-avatar>
                </div>
                <b-form class="comment-text d-flex align-items-center ml-3 mb-3 w-full" style="background:#f4f4f4;" action="javascript:void(0);">
                  <vue-editor
                    v-if='!(userData.user_role == "USERROLE11111" || userData.user_role == "USERROLE11118")'
                    v-model="vmCommentFormData.comment"
                    :editorToolbar="customToolbar"
                    :autofocus="true"
                    @keyup.enter="commentAdd()"
                    placeholder="Write a comment..."
                    class="w-full"
                  />
                  <vue-editor
                    v-else
                    v-model="vmCommentFormData.comment"
                    :editorToolbar="customToolbarAdmin"
                    :autofocus="true"
                    @keyup.enter="commentAdd()"
                    placeholder="Write a comment..."
                    class="w-full"
                  />

                  <div class="comment-attagement d-flex">
                    <b-link href="javascript:void(0);">
                      <i class="ri-send-plane-line mr-2" @click="commentAdd()"></i>
                    </b-link>
                  </div>
                </b-form>
              </div>

              <!-- post comment -->
              <ul class="post-comments p-0 m-0">
                <li class="mb-2" v-for="(postComment) in  Object.values(postCommentObj)" :key="postComment.comment_id">
                  <div class="d-flex w-full">
                    <div class="user-img" @click="viewProfile(postComment)">
                      <img v-if="postComment.user_admin" :src="gidelogo" alt="" class="avatar-35" rounded="circle" fluid />
                      <img v-else-if="postComment.org?.org_profile_img" :src="postComment.org.org_profile_img" alt="" class="avatar-35" rounded="circle" fluid />
                      <img v-else-if="postComment['user_image']" :src="postComment['user_image']" class="avatar-35" rounded="circle" fluid/>
                      <b-avatar v-else
                        :variant="getBgColorForLetter(postComment.org?.org_name ? postComment.org.org_name : postComment.user_name)"
                        :text="getFirstLetterOfAString(postComment.org?.org_name ? postComment.org.org_name : postComment.user_name)"
                        class="avatar-35" rounded="circle" fluid>
                      </b-avatar>
                    </div>
                    <div class="comment-data-block ml-3 w-full" >
                      <div class="d-flex flex-row  justify-content-start w-full">

                        <div class="w-full" >
                          <div v-if="postComment.comment_edited">
                            <!-- comment edit form -->
                            <b-form class="comment-text d-flex align-items-center mt-1" action="javascript:void(0);" style="background:#f4f4f4;">
                              <vue-editor
                                v-if='!(userData.user_role == "USERROLE11111" || userData.user_role == "USERROLE11118")'
                                v-model="vmComment"
                                :editorToolbar="customToolbar"
                                :autofocus="true"
                                @keyup.enter="commentEdit(postComment)"
                                placeholder="Write a comment..."
                              />
                              <vue-editor
                                v-else
                                v-model="vmCommentFormData.comment"
                                :editorToolbar="customToolbarAdmin"
                                :autofocus="true"
                                @keyup.enter="commentEdit(postComment)"
                                placeholder="Write a comment..."
                              />
                              <div class="comment-attagement d-flex">
                                <b-link href="javascript:void(0);">
                                  <i v-b-tooltip.hover.bottomright title="Cancel" class="far fa-times-circle mr-2" @click="commentEditHideInput(postComment)"></i>
                                </b-link>
                                <b-link href="javascript:void(0);">
                                  <i v-b-tooltip.hover.bottomright title="Ok" class="fas fa-check mr-1" @click="commentEdit(postComment)"></i>
                                </b-link>
                              </div>
                            </b-form>
                          </div>

                          <!-- comment normal view -->
                          <div v-else class="d-flex justify-content-start" >
                            <div class="d-flex flex-column py-1 px-2 rounded commentBox">
                              <h6 @click="viewProfile(postComment)">
                                {{ postComment.org?.org_name ? postComment.org.org_name : postComment.user_name}}
                              </h6>
                              <p class="mb-0">
                                <span v-html="postComment.comment"></span>
                              </p>
                            </div>
                            <div v-if="postComment.user_id === userData.user_id || userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" class="d-flex flex-col  justify-content-center align-items-center ml-3">
                              <b-dropdown  no-caret no-flip  size="sm" variant="light"  style="bacground: #fff !important;" >
                                <template #button-content>
                                  <i class="fas fa-ellipsis-h" style="color:#afafaf;"></i>
                                </template>
                                <b-dropdown-item-button @click="commentEditShowInput(postComment)" >
                                  Edit
                                </b-dropdown-item-button>
                                <b-dropdown-item-button @click="commentDelete(postComment.comment_id)" >
                                  Delete
                                </b-dropdown-item-button>
                              </b-dropdown>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="!postComment.comment_edited" class="d-flex flex-wrap align-items-center comment-activity" :key="updatedCommentList">
                        <b-link @click="commentLike(postComment.comment_id, 'COMMENT', postComment.comment_likedby_curr_user)">
                          <span class="text-secondary" v-if="postComment.comment_likedby_curr_user == 1">Unlike</span>
                          <span class="text-primary" v-else >Like</span>
                        </b-link>
                        <b-link href="javascript:void(0);" @click="commentReplyShowInput(postComment)">Reply
                           {{ postComment.comment_reply_count > 0 ? '('+ postComment.comment_reply_count + ')' : ''}}
                        </b-link>
                        <b-link @click="ugcx_add(postComment.comment_id, 'COMMENT')">
                          <span class="text-primary">Hide</span>
                        </b-link>
                        <b-link @click="showModelReportFn(postComment.comment_id, 'COMMENT')">
                          <span class="text-primary">Report</span>
                        </b-link>
                        <span>{{getFormatttedTimeAgo(postComment.created_on)}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- comment reply list -->
                  <PostThread :propPostComment="postComment" :propPostList="postCommentObj"></PostThread>
                </li>
              </ul>
              <p v-if="!allCommentLoaded" >
                <a href="#" @click.prevent="commentList()"> View  more comment </a>
              </p>
            </div>
          </div>
          <!-- <div class="col-1 iq-card-header-toolbar align-items-center">
            <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0 mr-0">
              <template v-slot:button-content>
                <b-link href="javascript:void(0)" class="text-secondary postThreeDotIcon">
                  <i class="ml-0 ri-more-2-line"></i>
                </b-link>
              </template>
              <a v-if="(userData.user_id === vmPostData.user_id) || (userData.user_role === 'USERROLE11111')" class="dropdown-item p-3" @click="openEditProfileModal()" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-pen-to-square"></i></div>
                  <div class="data ml-2">
                    <h6>Edit Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" @click="ugcx_add(vmPostData.post_id, 'POST')" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-eye-slash"></i></div>
                  <div class="data ml-2">
                    <h6>Hide Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" @click="showModelReportFn(vmPostData.post_id, 'POST')" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-circle-exclamation"></i></div>
                  <div class="data ml-2">
                    <h6>Report Post</h6>
                  </div>
                </div>
              </a>
              <a class="dropdown-item p-3" v-if="userData.user_role === 'USERROLE11111' || userData.user_id == vmPostData.user_id" @click="showAlertForDelete(vmPostData.post_id)" href="javascript:void(0)">
                <div class="d-flex align-items-top">
                  <div class="icon font-size-20"><i class="fa-solid fa-trash"></i></div>
                  <div class="data ml-2">
                    <h6>Delete Post</h6>
                  </div>
                </div>
              </a>
            </b-dropdown>
          </div> -->
        </div>
        <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
          <div v-html="toastMsg">
          </div>
        </b-toast>
        <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToPlanList()"/>

        <b-modal v-if="(reportModuleObjId && reportModuleName)"  v-model="showModelReport" scrollable size="md" centered title="Report " hide-footer  no-close-on-backdrop no-close-on-esc  >
          <ReportIssue  @emitCloseadminReportIssueAddModal="closeReportAddModal" :propModuleId="reportModuleObjId" :propModuleType="reportModuleName" :propOpenedInModal="true">
          </ReportIssue>
        </b-modal>

        <span v-if="showModelProfileEdit" scrollable size="md" centered title="Edit Post" hide-footer  no-close-on-backdrop no-close-on-esc  >
          <PostAdd :propPostType="vmPostData.post_type" :propModuleObjId="vmPostData.post_id" :propOpenedInModal="showModelProfileEdit" :propPostData="vmPostData" @emitCloseEditProfileModal="closeEditProfileModal" :propsPostId="vmPostData.post_id"></PostAdd>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { socialvue } from "../../../config/pluginInit"
import SocialMediaShare from "../../../components/SocialMediaShare.vue"
import PostThread from "./PostThread.vue"
import { Posts } from "../../../FackApi/api/post"
import { Likes } from "../../../FackApi/api/like"
import { Comments } from "../../../FackApi/api/comment"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility"
import AlertBox from "../../../components/AlertBox.vue"
import { UserContentExcludes } from "../../../FackApi/api/UserContentExclude.js"
import ReportIssue from "../../../components/ReportIssue.vue"
import PostAdd from "./PostAdd.vue"
import { VueEditor } from "vue2-editor"

export default {
  name: "PostDetail",
  components: {
    SocialMediaShare,
    PostThread,
    AlertBox,
    ReportIssue,
    PostAdd,
    VueEditor
  },
  data () {
    return {
      cvLoadingStatus: false,
      cvCarouselHeight: 1000,
      cvCarouselWidth: "70%",
      cvPostImages: [],
      vmPostData: {},
      postCommentObj: {},
      vmLikeFormData: Object.assign({}, this.initLikeFormData()),
      vmCommentFormData: Object.assign({}, this.initCommentFormData()),
      moduleData: {},
      showToast: false,
      toastMsg: null,
      toastVariant: null,
      toastTitle: "Comment",
      showUpgradeAlertBox: false,
      alertDesc: "Please upgrade to Gide Plus",
      alertTitle: "Post",
      okText: "Upgrade",
      cancelText: "Not now",
      showModelReport: false,
      reportModuleObjId: null,
      reportModuleName: "COMMENT",
      updatedCommentList: 0,
      showModelProfileEdit: false,
      gidelogo: process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE,
      allCommentLoaded: false,
      COMMENT_LIMIT: 10,
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        ["clean"] // remove formatting button
      ],
      customToolbarAdmin: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ]
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  methods: {
    /**
     * get Profile Image basis the post type
     */
    getProfileImage (post) {
      switch (post.post_type) {
        case 3: // Clubs
          return post.club.profile_pic.image
        case 1:
        case 2:
        case 4:
        case 5:
        default:
          return post.org && post.org.org_profile_img ? post.org.org_profile_img : post.user_image
      }
    },
    /**
     * get Profile Name basis the post type
     */
    getProfileName (post) {
      switch (post.post_type) {
        case 3: // Clubs
          return post.club.club_name
        case 1:
        case 2:
        case 4:
        case 5:
        default:
          return post.org && post.org?.org_id ? post.org.org_name : post.user_name
      }
    },
    /**
     * View Profile
     */
    viewProfile (comment) {
      let url = `/user/${comment.user_id}`
      if (comment.org_id) {
        url = `/organisation/${comment.org_id}`
      }
      this.$router.push(url)
    },
    /**
     * goToUserProfile
     */
    goToUserProfile (post) {
      try {
        let url = ""
        if (post.org && post.org.org_id) {
          url = `/organisation/${post.org.org_id}`
        }
        else if (post.post_type === 5 && post.user_admin && post.user_admin.toString() === "1") {
          url = `/user/1`
        }
        else if (post.post_type === 3) {
          url = `/club/${post.club.club_id}`
        }
        else {
          url = `/user/${post.user_id}`
        }

        this.$router.push(url)
      }
      catch (err) {
        console.error("Exception occurred at goToUserProfile() and Exception:", err.message)
      }
    },
    /**
     * loadRequiredData
     */
    async loadRequiredData () {
      await socialvue.index()
      await this.postView()
      await this.commentList()
    },
    /**
     * initCommentFormData
     */
    initCommentFormData () {
      return {
        "module_id": this.$route.params.post_id,
        "module_name": "POST",
        "comment": "",
        "comment_parent_id": null
      }
    },
    /**
     * initLikeFormData
     */
    initLikeFormData () {
      return {
        "module_id": "",
        "module_name": "",
        "like_status": 0
      }
    },
    /**
     * getCommentImage
     */
    getCommentImage (comment) {
      if (comment.user_id === this.userData.user_id) {
        if (this.userData.user_image && this.userData.user_image.profile_pic) {
          return this.userData.user_image.profile_pic.image_thumbnail
        }
      }
      return false
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getFormatttedTimeAgo()
     */
    getFormatttedTimeAgo (dateTime) {
      return Utility.getFormatttedTimeAgo(dateTime)
    },
    /**
     * showPrevImage ()
     */
    showPrevImage () {
      try {
        this.$refs.image_carousel.prev()
      }
      catch (err) {
        console.error("Exception occurred at showPrevImage () and Exception", err.message)
      }
    },
    /**
     * showPrevImage ()
     */
    showNextImage () {
      try {
        this.$refs.image_carousel.next()
      }
      catch (err) {
        console.error("Exception occurred at showNextImage () and Exception", err.message)
      }
    },
    /**
     * postView
     */
    async postView () {
      try {
        this.cvLoadingStatus = true
        let postId = this.$route.params.post_id

        let postViewResp = await Posts.postView(this, postId)
        if (postViewResp && !postViewResp.resp_status) {
          return false
        }
        else {
          this.vmPostData = postViewResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception occurred at postView() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * postLike()
     * @param likeStatus whether module is liked by current user
     */
    async postLike (moduleId, moduleName, likeStatus) {
      try {
        this.vmLikeFormData.module_id = moduleId
        this.vmLikeFormData.module_name = moduleName
        this.vmLikeFormData.like_status = likeStatus ? 0 : 1
        let likeAddResp = await Likes.likeAdd(this, this.vmLikeFormData)
        if (likeAddResp && !likeAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, likeAddResp)
        }
        else {
          // local updating the like for postDetail
          if (this.vmLikeFormData.like_status) {
            this.vmPostData.post_like_count += 1
            this.vmPostData.post_likedby_curr_user = 1
          }
          else {
            this.vmPostData.post_like_count -= 1
            this.vmPostData.post_likedby_curr_user = 0
          }

          // global
          this.$store.dispatch("Post/postLikeUnlikeAction", this.vmLikeFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at  postLike () and Exception", err.message)
      }
    },
    /**
     * commentList
     */
    async commentList () {
      try {
        let payload = {
          module_id: this.vmPostData.post_id,
          limit: this.COMMENT_LIMIT,
          offset: Object.keys(this.postCommentObj).length
        }

        let commentListResp = await Comments.commentList(this, payload)
        if (commentListResp && commentListResp.resp_status) {
          this.postCommentObj = { ...this.postCommentObj, ...commentListResp.resp_data.comment_data }
        }
        else {
          this.allCommentLoaded = true
        }
      }
      catch (err) {
        console.error("Exception occurred at commentList() and Exception:", err.message)
      }
    },
    /**
     * commentAdd
     */
    async commentAdd () {
      try {
        this.cvLoadingStatus = true

        // Format the comment data
        this.vmCommentFormData.comment = this.vmCommentFormData.comment.replace(/\n\r?/g, "<br>")

        this.vmCommentFormData.comment = this.vmCommentFormData.comment.trim()
        let matches = this.vmCommentFormData.comment.match(/\bhttps?:\/\/\S+/gi)

        if (matches && matches.length > 0) {
          for (let i in matches) {
            let url = matches[i]
            this.vmCommentFormData.comment = this.vmCommentFormData.comment.replace(url, `<a href='${url}' target='_blank'>${url}</a>`)
          }
        }

        let commentAddResp = await Comments.commentAdd(this, this.vmCommentFormData)
        if (!commentAddResp.resp_status && commentAddResp.resp_code === "ERR_USER_NOT_SUBSCRIBED") {
          this.showUpgradeAlertBox = true
        }
        else if (commentAddResp && !commentAddResp.resp_status) {
          await ApiResponse.responseMessageDisplay(this, commentAddResp)
          return false
        }
        else {
          // make comment obj
          let commentAddData = {}
          let commentId = commentAddResp.resp_data.data.comment_id
          commentAddData[commentId] = { ...commentAddResp.resp_data.data }
          commentAddData[commentId].user_name = this.userData.user_name
          commentAddData[commentId].user_image = this.userData?.user_image?.profile_pic?.image_thumbnail ? this.userData.user_image.profile_pic.image_thumbnail : null
          commentAddData[commentId].comment_edited = false
          commentAddData[commentId].comment_reply = false
          commentAddData[commentId].comment_show_more = false

          if (this.userData.user_role === "USERROLE11111") {
            commentAddData[commentId].user_admin = 1
            commentAddData[commentId].user_name = "Gide"
            commentAddData[commentId].user_image = process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
          }
          else if (this.userData.user_role === "USERROLE11118") {
            commentAddData[commentId].user_admin = 1
            commentAddData[commentId].user_name = "Gide Counsellor"
            commentAddData[commentId].user_image = process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
          }
          else {
            commentAddData[commentId].user_name = this.userData.user_name
            commentAddData[commentId].user_image = this.userData?.user_image?.profile_pic?.image_thumbnail ? this.userData.user_image.profile_pic.image_thumbnail : null
          }

          // make new  comment obj
          this.postCommentObj = Object.assign({}, { ...commentAddData, ...this.postCommentObj })

          // update post count to vuex store
          let editPostCountData = {}
          editPostCountData.postId = this.vmCommentFormData.module_id
          editPostCountData.updatationType = "ADD"
          this.$store.dispatch("Post/editPostCommentCountAction", editPostCountData)
        }

        this.vmPostData.post_comment_count += 1
        this.vmCommentFormData = Object.assign({}, this.initCommentFormData())
      }
      catch (err) {
        console.error("Exception occurred at commentAdd() and Exception:", err)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * commentDelete
     */
    async commentDelete (commentId) {
      try {
        let commentDeleteResp = await Comments.commentDelete(this, commentId)
        await ApiResponse.responseMessageDisplay(this, commentDeleteResp)
        if (!commentDeleteResp.resp_status) {
          return false
        }
        else {
          // update post count to vuex store
          let editPostCountData = {}
          editPostCountData.postId = this.vmCommentFormData.module_id
          editPostCountData.updatationType = "DEL"
          this.$store.dispatch("Post/editPostCommentCountAction", editPostCountData)
          this.$delete(this.postCommentObj, commentId)
          this.vmPostData.post_comment_count -= 1
        }
      }
      catch (err) {
        console.error("Exception occurred at commentList() and Exception:", err.message)
      }
    },
    /**
     * commentEdit()
    */
    async commentEdit (postCommentObj) {
      try {
        let editedCommentData = { ...postCommentObj, comment: this.vmComment }
        let commentEditResp = await Comments.commentEdit(this, editedCommentData)
        await ApiResponse.responseMessageDisplay(this, commentEditResp)
        if (commentEditResp && !commentEditResp) {
          this.commentEditHideInput(postCommentObj)
          return false
        }
        else {
          // update the edited value
          let commentId = postCommentObj.comment_id
          this.postCommentObj[commentId] = { ...postCommentObj, comment: this.vmComment, comment_edited: false }
        }
      }
      catch (err) {
        console.error("Exception occurred at commentEdit () and Exception:", err.message)
      }
    },
    /**
     * commentEditShowInput()
     */
    commentEditShowInput (postComment) {
      try {
        // set value to edit from input
        this.vmComment = postComment.comment
        // opening the edit form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_edited: true }
      }
      catch (err) {
        console.error("Exception occurred at commentEditShowInput() and Exception", err.message)
      }
    },
    /**
     * commentEditHideInput()
     */
    commentEditHideInput (postComment) {
      try {
        // set edit form input value to empty string
        this.vmComment = ""
        // closing the edit form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_edited: false }
      }
      catch (err) {
        console.error("Exception occurred at commentEditHideInput() and Exception", err.message)
      }
    },
    /**
     * commentReplyShowInput()
     */
    commentReplyShowInput (postComment) {
      try {
        // opening the reply form
        let commentId = postComment.comment_id
        this.postCommentObj[commentId] = { ...postComment, comment_reply: true }
      }
      catch (err) {
        console.error("Exception occurred at commentReplyShowInput() and Exception", err.message)
      }
    },
    /**
     * commentLike
    */
    async commentLike (moduleId, moduleName, likeStatus) {
      try {
        this.vmLikeFormData.module_id = moduleId
        this.vmLikeFormData.module_name = moduleName
        this.vmLikeFormData.like_status = likeStatus ? 0 : 1
        let likeAddResp = await Likes.likeAdd(this, this.vmLikeFormData)
        if (likeAddResp && !likeAddResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, likeAddResp)
        }
        else {
          let likeUnlikeData
          if (this.vmLikeFormData.like_status) {
            likeUnlikeData = { ...this.postCommentObj[moduleId], comment_likedby_curr_user: 1 }
          }
          else {
            likeUnlikeData = { ...this.postCommentObj[moduleId], comment_likedby_curr_user: 0 }
          }
          this.postCommentObj[moduleId] = likeUnlikeData
        }
      }
      catch (err) {
        console.error("Exception occurred at commentLike () and Exception", err.message)
      }
    },
    /**
     * showMoreComment()
     */
    showMoreComment (postCommentObj) {
      try {
        let commentId = postCommentObj.comment_id
        this.postCommentObj[commentId] = { ...postCommentObj, comment_show_more: true }
      }
      catch (err) {
        console.error("Exception occurred at showMoreComment() and Exception:", err.message)
      }
    },
    /**
     * ugcx_add
     */
    async ugcx_add (moduleObjId, moduleName) {
      try {
        let userContentExcludeObj = {}
        userContentExcludeObj.module_obj_id = moduleObjId
        userContentExcludeObj.module_name = moduleName

        let ugcxResp = await UserContentExcludes.userContentExcludeAdd(this, userContentExcludeObj)
        if (ugcxResp.resp_status) {
          if (moduleName === "COMMENT") {
            this.$delete(this.postCommentObj, moduleObjId)
            this.updatedCommentList += 1
          }
          else {
            // Post
            this.$delete(this.vmPostData, moduleObjId)
            window.location = "/home"
          }
        }
        else {
          window.location = "/home"
          ApiResponse.responseMessageDisplay(this, ugcxResp)
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at ugcx_add() and Exception:", err)
      }
    },
    /**
     * showModelReportFn
     */
    showModelReportFn (moduleObjId, moduleName) {
      this.reportModuleObjId = moduleObjId
      this.reportModuleName = moduleName
      this.showModelReport = true
    },
    /**
     * closeReportAddModal
     */
    closeReportAddModal () {
      this.showModelReport = false
    },
    /**
     * openEditProfileModal()
     */
    openEditProfileModal () {
      try {
        if (this.showModelProfileEdit) {
          return null
        }
        this.showModelProfileEdit = true
      }
      catch (err) {
        console.error("Exception occurred at  openEditProfileModal() and Exception:", err.message)
      }
    },
    /**
     * closeEditProfileModal()
     */
    closeEditProfileModal () {
      try {
        this.showModelProfileEdit = false
      }
      catch (err) {
        console.error("Exception occurred at  closeEditProfileModal() and Exception:", err.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .w-full{
    width: 100%;
  }
  .btn-opacity{
    border: none ;
    outline : none;
    background:#fff;
    width: 100%;
  }
  .btn-opacity:hover{
    color: var(--iq-primary)
  }
  .line-wrap{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr)
  }
  @media screen and (max-width: 768px){
    .grid{
       grid-template-columns: 0.9fr 1.2fr 0.9fr
    }
  }
  .slide_control_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
  }
  .slide_control_icon{
   font-size: 2rem;
  }
  .carousel-indicators li {
    border-color: #e100ff;
    border-width: 12px;
    border-style: none solid none solid;
    background-color: #e100ff;
    height: 2px;
    margin: 0 16px;
    opacity: 1;
    padding: 1px;
    position: relative;
  }
  .carousel-indicators li::after {
    bottom: -7px;
    content: none;
    left: -7px;
    padding: 1px;
    position: absolute;
    right: -7px;
    top: -7px;
  }
  .carousel-indicators li.active {
    background-color: #7f00ff;
    border-color: #7f00ff;
    border-width: 12px;
    border-style: none solid none solid;
  }
  .postSlideParentDiv {
    .leftArrowIcon {
      position: absolute !important;
      left: 4px !important;
      z-index: 99 !important;
    }
    .rightArrowIcon {
        position: absolute !important;
        right: 4px !important;
    }
  }
  .comment-attagement{
    bottom: 2px !important;
    right: 2px !important;
  }
  #postDetail{
    padding: 15px;
  }
</style>
